$(function () {
    $('[data-menu-toggle]').on('click', function () {
        $(window).scrollTop(0);
        $(this).toggleClass('is-active');
        $('[data-menu]').toggle();
        $('body').toggleClass('is-fixed');
    });

    $('[data-dropdown]').on('click', function () {
        $(this).toggleClass('is-open');
    });
});